<template>
  <div class="flex-center margin-top-16" style="gap:20px">
    <btn-signin-kakao></btn-signin-kakao>
    <btn-signin-naver></btn-signin-naver>
    <!-- <btn-signin-facebook></btn-signin-facebook> -->
    <btn-signin-google></btn-signin-google>
  </div>
</template>

<script>
import BtnSigninKakao from './BtnSigninKakao';
// import BtnSigninFacebook from "./BtnSigninFacebook";
import BtnSigninNaver from './BtnSigninNaver';
import BtnSigninGoogle from './BtnSigninGoogle';
export default {
  name: 'SnsSignin',
  props: {
    signin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BtnSigninGoogle,
    BtnSigninNaver,
    // BtnSigninFacebook,
    BtnSigninKakao,
  },
  created() {
    if (this.$route.query.user_id && this.signin) {
      if (this.loading) return;
      this.loading = true;
      this.$axios
        .post('auth/user/login/social', {
          username: this.$route.query.user_id,
          password: '',
          token: this.$route.query.token,
        })
        .then((res) => {
          this.loading = false;
          this.$store.commit('setUser', res.data.user);
          this.setAuthHeader();
          if (this.$store.getters.trial) {
            this.$router.push('/service_survey');
          } else if (this.$store.getters.prevPath === '') {
            this.$router.push('mypage/my_service');
          } else {
            let path = this.$store.getters.prevPath;
            this.$store.commit('setPrevPath', '');
            this.$router.replace(path);
          }
          this.setGa('소셜로그인', '응답', '로그인');
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {},
};
</script>

<style lang="stylus" scoped></style>
